'use client'
import ProhibitedNotice from './ProhibitedNotice'

import AddressFieldToggle from '@/donationForm/_dependencies/components/DonationForm/formParts/AddressFieldToggle'
import CompanyFields from '@/donationForm/_dependencies/components/DonationForm/formParts/CompanyFields'
import Debug from '@/donationForm/_dependencies/components/DonationForm/formParts/Debug'
import DonationAmountField from '@/donationForm/_dependencies/components/DonationForm/formParts/DonationAmountField'
import DonationFormCaptcha from '@/donationForm/_dependencies/components/DonationForm/formParts/DonationFormCaptcha'
import GeneralError from '@/donationForm/_dependencies/components/DonationForm/formParts/GeneralError'
import HolderHeadline from '@/donationForm/_dependencies/components/DonationForm/formParts/HolderHeadline'
import HolderTitle from '@/donationForm/_dependencies/components/DonationForm/formParts/HolderTitle'
import OfflineMessage from '@/donationForm/_dependencies/components/DonationForm/formParts/OfflineMessage'
import PaymentMethodError from '@/donationForm/_dependencies/components/DonationForm/formParts/PaymentMethodError'
import PaymentMethodSelection from '@/donationForm/_dependencies/components/DonationForm/formParts/PaymentMethodSelection'
import StripeCCFields from '@/donationForm/_dependencies/components/DonationForm/formParts/StripeFields/StripeCCFields'
import StripeSEPADebitFields from '@/donationForm/_dependencies/components/DonationForm/formParts/StripeFields/StripeSEPADebitFields'
import SubmitButton from '@/donationForm/_dependencies/components/DonationForm/formParts/SubmitButton'
import Summary from '@/donationForm/_dependencies/components/DonationForm/formParts/Summary'
import Terms from '@/donationForm/_dependencies/components/DonationForm/formParts/Terms'

import IFrameAddressFields from './IFrameAddressFields'
import IFrameExplanation from './IFrameExplanation'
import IFrameHolderFields from './IFrameHolderFields'
import IFrameIntervalSelection from './IFrameIntervalSelection'
import TaxDeductibilityNotice from './TaxDeductibilityNotice'
import classNames from 'classnames'
import styles from './IFrameForm.module.css'
import { Button, Heading, IconButton, LayoutStack } from '@betterplace/design-system/client'
import { ReportableError } from '@betterplace/error-reporting'
import { SSLLogo } from '@/donationForm/_dependencies/components/DonationForm/formParts/TrustLogos'
import { StepProps } from './types'
import { keysOf } from '@betterplace/utils'
import { postMessageScrollTop } from '@/helpers/utils'
import { report } from '@/errorReporting'
import { useCallback, useEffect } from 'react'
import { useDonationFormContext, useDonationFormValues } from '@/donationForm/_dependencies/helpers'
import { useMessageContext } from '@/components/Messages'
import { useTranslations } from 'next-intl'
import type { DonationFormProps } from '@/donationForm/types'

function postMessageFormLoading() {
  if (window.parent) window.parent.postMessage('bpIframeLoadStart', '*')
}

function IFrameBlockWrapper({ headingText, children }: { headingText: string; children: React.ReactNode }) {
  return (
    <div className={styles.blockWrapper}>
      <Heading level="h200" className={styles.headingMargin}>
        {headingText}
      </Heading>
      {children}
    </div>
  )
}

function Step1({ visible }: StepProps) {
  const t = useTranslations('nextjs')
  const [companyDonation] = useDonationFormValues(['_company_donation'])
  const { addMessage } = useMessageContext()
  const {
    trigger,
    setValue,
    getValues,
    formState: { errors },
  } = useDonationFormContext()
  const validateAndGoToNextStep = useCallback(async () => {
    try {
      const valid = await trigger(undefined, { shouldFocus: true })
      postMessageScrollTop()
      if (valid) {
        setValue('_step', 2)
        return
      }
      // set all fields with errors to touched
      for (const key of keysOf(errors)) {
        if (key === 'root') continue
        if (errors[key]) {
          const value = getValues(key)
          setValue(key, value, { shouldTouch: true })
        }
      }
    } catch (error) {
      report(error as ReportableError)
      addMessage({ type: 'error', message: t('errors.messages.validate') })
    }
  }, [trigger, setValue, getValues, errors, addMessage, t])

  const onContinueClicked = useCallback(() => {
    void validateAndGoToNextStep()
  }, [validateAndGoToNextStep])

  return (
    <div className="donate-iframe-content" style={{ display: visible ? 'block' : 'none' }}>
      <div className={classNames('max-w-screen-xs', styles.iFrameInnerContainer)}>
        <GeneralError />
        <OfflineMessage />
        <IFrameBlockWrapper headingText={t('donate.iframe.recurring_headline')}>
          <IFrameIntervalSelection />
        </IFrameBlockWrapper>
        <IFrameBlockWrapper headingText={t('donate.iframe.amount_headline')}>
          <DonationAmountField className={styles.donationAmountInput} invisibleLabel />
        </IFrameBlockWrapper>
        <PaymentMethodSelection layout="horizontal" className={styles.paymentMethodSelection} />
        <StripeCCFields />
        <StripeSEPADebitFields />
        <PaymentMethodError />

        <LayoutStack space="400" className={styles.blockWrapper}>
          <HolderHeadline />
          <IFrameHolderFields />

          {companyDonation && (
            <>
              <HolderTitle />
              <CompanyFields />
              <IFrameAddressFields required />
            </>
          )}
          {!companyDonation && (
            <div className={styles.blockWrapper}>
              <AddressFieldToggle>
                <IFrameAddressFields required />
              </AddressFieldToggle>
            </div>
          )}
        </LayoutStack>

        <div className={styles.continueContainer}>
          <Button onClick={onContinueClicked} type="button" kind="primary" className="iframeButton">
            {t('donate.iframe.continue_button')}
          </Button>
          <SSLLogo className={styles.sslLogo} />
        </div>
        <DonationFormCaptcha />
      </div>
    </div>
  )
}

function Step2({ visible }: StepProps) {
  return (
    <div className="donate-iframe-content" style={{ display: visible ? 'block' : 'none' }}>
      <div className={classNames('max-w-screen-xs', styles.iFrameInnerContainer)}>
        <Summary explanation={<IFrameExplanation />} />
        <div className={styles.continueContainer}>
          <SubmitButton className="iframeButton" />
          <SSLLogo className={styles.sslLogo} />
        </div>
        <TaxDeductibilityNotice />
        <br />
        <Terms />
      </div>
    </div>
  )
}

function IFrameForm({ config: { donationsProhibited }, ...props }: DonationFormProps) {
  const t = useTranslations('nextjs.donate.iframe')
  const [step] = useDonationFormValues(['_step'])
  const { setValue } = useDonationFormContext()

  const goBack: React.MouseEventHandler = useCallback(
    (event) => {
      event.preventDefault()
      postMessageScrollTop()
      setValue('_step', 1)
    },
    [setValue]
  )

  useEffect(() => {
    postMessageFormLoading()
  }, [])

  if (donationsProhibited) return <ProhibitedNotice />

  return (
    <>
      <form {...props}>
        <div>
          <div className={classNames('donate-iframe-header', styles.iFrameHeader)}>
            <div
              className={classNames('donate-iframe-header-wrapper max-w-screen-xs', styles.iFrameHeaderInnerContainer)}
            >
              <div className={styles.headline}>
                <div className={styles.column}>
                  {step === 2 ? (
                    <IconButton
                      kind="plain"
                      size="small"
                      iconName="arrowLeft"
                      isGhost
                      onClick={goBack}
                      className={styles.backButton}
                    >
                      {t('back_link')}
                    </IconButton>
                  ) : (
                    <>&nbsp;</>
                  )}
                </div>
                <div className={styles.headingContainer}>
                  <Heading as="h1" level="h200">
                    {t('headline')}
                  </Heading>
                  <p className="line2">({step}/2)</p>
                </div>
                <div className={styles.column}>&nbsp;</div>
              </div>
            </div>
          </div>
          <Step1 visible={step === 1} />
          <Step2 visible={step === 2} />
        </div>
      </form>
      <Debug initialFFs={props.initialFFs} initialFormValues={props.initialFormValues} />
    </>
  )
}

export default IFrameForm
