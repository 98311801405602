import DonationFormField from '../../../shared/DonationFormField'
import FormToggleButton from '@/donationForm/_dependencies/components/shared/FormToggleButton'
import MonthlyMatchingBanner from '../MonthlyMatchingBanner'
import styles from './IntervalSelection.module.css'
import { DonationIntervals } from '@betterplace/api-graphql-types'
import { Fieldset, Heading, Tooltip } from '@betterplace/design-system/client'
import { toLowerCase } from '@betterplace/utils'
import {
  useDonationFormConfig,
  useDonationFormContext,
  useDonationFormValues,
} from '@/donationForm/_dependencies/helpers'
import { useTranslations } from 'next-intl'

const intervals = [DonationIntervals.Single, DonationIntervals.Monthly, DonationIntervals.Yearly]

function IntervalSelection() {
  const t = useTranslations('nextjs')
  const {
    formState: { errors },
  } = useDonationFormContext()

  const { showRecurring, recurringPaymentMethods } = useDonationFormConfig()
  const [paymentMethod] = useDonationFormValues(['payment_method'])

  if (!showRecurring) {
    return null
  }

  const isRecurringPaymentMethod = recurringPaymentMethods.includes(paymentMethod)

  /**   Before we had: <LayoutStack space="200">, but there's a flexbox bug in Safari which causes the flex container to exhibit additional gap    */
  return (
    <div>
      <Fieldset
        legend={
          <Heading level="h200" className={styles.heading}>
            {t('donate.attributes.interval')}
          </Heading>
        }
        error={errors.interval?.message}
        layoutProps={{
          alignment: 'justified',
          space: '100',
        }}
      >
        {intervals.map((interval) => {
          const isDisabled = !isRecurringPaymentMethod && interval !== DonationIntervals.Single

          return (
            <div key={interval}>
              <Tooltip content={t('donate.recurring_disabled_tooltip')} inactive={!isDisabled}>
                <DonationFormField
                  name="interval"
                  type="radio"
                  as={FormToggleButton}
                  value={interval}
                  size="small"
                  disabled={isDisabled}
                  className={styles.radioButton}
                >
                  {t(`donate.recurring_interval.${toLowerCase(interval)}`)}
                </DonationFormField>
              </Tooltip>
            </div>
          )
        })}
      </Fieldset>
      <MonthlyMatchingBanner className={styles.matchingBanner} />
    </div>
  )
}

export default IntervalSelection
